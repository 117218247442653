import { getSessionToken } from '../../auth'
import httpClient from '../../gateway/http_request'

const Recaptcha = {
  async verify(data) {
    const path = '/recaptcha/verify'
    let headers = { 'session-token': getSessionToken() }

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.post(path, data, headers)
    return response
  }
}

export default Recaptcha
