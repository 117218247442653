import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Recaptcha from '../services/recaptcha/recaptcha';
import { PENALTY_LEVEL_NONE, PENALTY_LEVEL_CAPTCHA } from '../constants/PenaltyLevel';

const PenaltyContext = createContext();

export const PenaltyProvider = ({ children }) => {
  const [penaltyLevel, setPenaltyLevel] = useState(0);
  const [captchaSolved, setCaptchaSolved] = useState(false);
  const history = useHistory();

  const activateCaptcha = () => {
    setPenaltyLevel(PENALTY_LEVEL_CAPTCHA);
    setCaptchaSolved(false);
  };

  const handlePenaltyLevel = (level) => {
    setPenaltyLevel(level);
    if (level === PENALTY_LEVEL_CAPTCHA) setCaptchaSolved(false);
  };

  const handleCaptchaSuccess = async (value) => {
    const payload = {
      recaptcha_value: value,
    };

    try {
      const response = await Recaptcha.verify(payload);
      const data = await response.json(); 

      if (data.success) {
        setCaptchaSolved(true);
        setPenaltyLevel(PENALTY_LEVEL_NONE);
        history.push('/');
      } else {
        setCaptchaSolved(false);
        setPenaltyLevel(PENALTY_LEVEL_CAPTCHA);
      }
    } catch (error) {
      console.error("Erro ao verificar o CAPTCHA:", error);
    }
  };

  return (
    <PenaltyContext.Provider
      value={{ penaltyLevel, captchaSolved, handlePenaltyLevel, handleCaptchaSuccess, activateCaptcha }}
    >
      {children}
    </PenaltyContext.Provider>
  );
};

export const usePenalty = () => useContext(PenaltyContext);
