import React, { useEffect } from 'react';
import { usePenalty } from '../../contexts/PenaltyContext';
import ReCAPTCHA from 'react-google-recaptcha';
import services from '../../services';
import { PENALTY_LEVEL_CAPTCHA } from "../../constants/PenaltyLevel";

export default function CaptchaOverlay() {
  const { penaltyLevel, captchaSolved, handleCaptchaSuccess } = usePenalty();


  useEffect(() => {
    if (penaltyLevel === PENALTY_LEVEL_CAPTCHA && !captchaSolved) {
      services.Trackings.trackCaptchaDisplayed();
    }
  }, [penaltyLevel, captchaSolved]);

  if (penaltyLevel !== PENALTY_LEVEL_CAPTCHA || captchaSolved) return null;

  return (
    <div style={overlayStyles}>
      <div style={captchaContainerStyles}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={value => handleCaptchaSuccess(value)}
        />
      </div>
    </div>
  );
};

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const captchaContainerStyles = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
};
