/* eslint-disable */
import { getSessionToken } from '../../auth'
import httpClient from '../../gateway/http_request'
import queryString from 'query-string'
import Bugsnag from '@bugsnag/js'
import services from '../'
import { getDeviceType, getUserIp } from '../../utils'

const Trackings = {
  async sendTrack(data) {
    const path = 'tracker/api/v1/event'
    let headers = { 'session-token': getSessionToken() }

    headers = httpClient.buildHeader(headers)

    data = {
      ...data,
      screen_width: window.screen.width,
      browser_language: navigator.language,
      path: window.location.pathname,
      referrer: document.referrer,
      url: window.location.href,
      user_agent: navigator.userAgent,
      ipv4: await getUserIp(),
      device_type: getDeviceType(),
      user_id: services.Users.getUserId(),
      integration_type: services.Users.getIntegrationType(),
      session_token: getSessionToken(),
      sended_at: new Date().toISOString()
    }

    const response = await httpClient.post(path, data, headers)
    return response
  },

  async bookDetails(book, payload) {
    const queryStrings = queryString.parse(window.location.search)
    const page = queryStrings.page || 1
    const searchTerm = queryStrings.query

    try {
      const response = await Trackings.sendTrack({
        event: 'opened_book_details',
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async lastBookDetails(book, payload) {
    const queryStrings = queryString.parse(window.location.search)
    const page = queryStrings.page || 1
    const searchTerm = queryStrings.query

    try {
      const response = await Trackings.sendTrack({
        event: 'opened_recent_book_details',
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async changeViewMode(viewMode) {
    try {
      const response = await Trackings.sendTrack({
        event: 'changed_shelf_display_mode'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async copyAbnt(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'copied_bibliographic_reference',
        resource_uuid: payload.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async search(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'performed_search'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async login() {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'logged_in'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async integrationLogin() {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'logged_in'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async ticketActivation(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'tried_to_activate_code'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async changePage(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'changed_shelf_page'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async visitTicketPage(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_code_activation_page'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async logout() {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'logged_out'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },
  async trackOpenBook(book, payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_book',
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackOpenLastBook(book, payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_recent_book',
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackRecommendations(book, payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_recommended_book_details',
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackOpenRecommendedBook(book, payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_recommended_book',
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackOpenPDF(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_pdf',
        resource_name: payload.card
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackOpenFavorites(book, payload) {
    try {
      const response = await Trackings.sendTrack({
        event: payload.event,
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackCookies() {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'accepted_cookies'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackCategory(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'filtered_by_category'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

  async trackECommerce(book, payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_ecommerce',
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },
  async trackOpenPeriodics(payload) {
    try {
      const response = await services.Trackings.sendTrack({
        event: 'opened_journals_page'
      })

      if (!response.ok) {
        const errors = await response.json()

        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },
  async favoritesTracking(book, payload) {
    try {
      const response = await Trackings.sendTrack({
        event: payload.event,
        resource_name: book.titleCover,
        resource_uuid: book.uuid
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },
  async acessibilityManualTracking() {
    try {
      const response = await Trackings.sendTrack({
        event: 'opened_accessibility_manual'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },
  async manualTracking() {
    try {
      const response = await Trackings.sendTrack({
        event: 'opened_manual'
      })

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },
  async trackCaptchaDisplayed() {
    try {
      const response = await Trackings.sendTrack({
        event: 'captcha_displayed'
      });

      if (!response.ok) {
        const errors = await response.json();
        throw errors;
      }
    } catch (error) {
      Bugsnag.notify(error);
    }
  },

}

export default Trackings
